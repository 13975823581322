/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import remark from "remark";
import remarkHTML from "remark-html";
import StyledLink from "./StyledLink";
import ImageCarousel from "../components/ImageCarousel";

const Sectionblocklarge = ({ title, text, href, linktext, imagegallery }) => {
  const value = remark().use(remarkHTML).processSync(text).toString();
  return (
    <section
      sx={{
        display: "flex",
        flexDirection: ["column"],
        margin: "0 auto",
      }}
    >
      <article
        sx={{
          mx: [4, 4, 5],
          mt: [6, 6],
          mb: [3, 6],
          display: ["flex"],
          flexDirection: ["column", "row"],
          maxWidth: "1600px",
        }}
      >
        <h2
          sx={{
            flex: [1, 1.5],
            textTransform: "capitalize",
            fontSize: [5, null, 6],
            lineHeight: 1.2,
            mb: [2],
            mt: [0, "-16px", "-30px"],
          }}
        >
          {title}
        </h2>
        <div sx={{ flex: [1, 4], a: { mt: 5 } }}>
          <div
            sx={{
              position: "relative",
              pt: 3,
              display: ["none", "block"],
              ":before": {
                content: '" "',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: " 0.7em",
                borderTop: "1px solid black",
              },
              p: { my: 3 },
              h3: { fontSize: 4, mt: 5 },
              h2: { fontSize: 4, mt: 5 },
              h1: { fontSize: 4, mt: 5 },
            }}
            dangerouslySetInnerHTML={{ __html: value }}
          />
          <div
            sx={{
              display: ["block", "none"],
              p: { my: 3 },
              h3: { fontSize: 3, mt: 4 },
              h2: { fontSize: 3, mt: 4 },
              h1: { fontSize: 3, mt: 4 },
            }}
            dangerouslySetInnerHTML={{ __html: value }}
          />
          {href ? <StyledLink href={href} text={linktext} /> : null}
        </div>
      </article>
      {imagegallery && imagegallery.length > 0 ? (
        <div
          sx={{
            mx: [4, 4, 5],
            mb: [6],
            div: {
              height: ["300px", "400px", "600px", "750px"],
              div: {
                height: "100%",
              },
            },
          }}
        >
          <ImageCarousel images={imagegallery} />
        </div>
      ) : null}
    </section>
  );
};

export default Sectionblocklarge;

Sectionblocklarge.propTypes = {
  section: PropTypes.object,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  linktext: PropTypes.string,
  imagegallery: PropTypes.array,
};
