/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BackgroundImg from "../components/BackgroundImg";
import Sectionblocklarge from "../components/SectionBlockLarge";
import Sectionblockmargin from "../components/SectionBlockMargin";

export const ServicesPageTemplate = ({
  heroimagetitle,
  heroalt,
  heroimage,
  title,
  alt,
  image,
  section,
  content,
  linkretailerpage,
  linkprojectspage,
  linklandingpage,
  linkproductspage,
  linkbrandpage,
  linkcontactpage,
  projectlink,
  productlink,
  linktitle,
}) => {
  const [servicesLink, setServicesLink] = useState();

  useEffect(() => {
    if (linkretailerpage === true) {
      setServicesLink("/retailers");
    } else if (linkprojectspage === true) {
      setServicesLink("/projects");
    } else if (linkproductspage === true) {
      setServicesLink("/products");
    } else if (linklandingpage === true) {
      setServicesLink("/");
    } else if (productlink === true) {
      setServicesLink(`/products/${productlink}`);
    } else if (projectlink === true) {
      setServicesLink(`/projects/${projectlink}`);
    } else if (linkbrandpage === true) {
      setServicesLink("/brand");
    } else if (linkcontactpage === true) {
      setServicesLink("/contact");
    }
  }, [
    linkretailerpage,
    linkprojectspage,
    linkproductspage,
    linklandingpage,
    productlink,
    projectlink,
    linkbrandpage,
    linkcontactpage,
  ]);
  return (
    <section>
      {heroimage ? (
        <BackgroundImg
          imageInfo={{
            image: heroimage,
            alt: heroalt,
            title: heroimagetitle,
          }}
        />
      ) : null}

      <Sectionblockmargin
        content={content}
        title={title}
        alt={alt}
        image={image}
        href={servicesLink}
        linktext={linktitle}
      />

      {section?.map((section, index) => {
        let link;
        if (section.brandpagelink === "true") {
          link = "/brand";
        } else if (section.projectspagelink === "true") {
          link = "/projects";
        } else if (section.productspagelink === "true") {
          link = "/products";
        } else if (section.landingpagelink === "true") {
          link = "/";
        } else if (section.productlink === "true") {
          link = `/products/${section.productlink}`;
        } else if (section.projectlink === "true") {
          link = `/projects/${section.projectlink}`;
        }
        return (
          <div key={index}>
            <Sectionblocklarge
              imagegallery={section.imagegallery}
              title={section.title}
              text={section.body}
              href={link}
              linktext={section.linktitle}
            />
          </div>
        );
      })}
    </section>
  );
};

ServicesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  heroimagetitle: PropTypes.string,
  heroalt: PropTypes.string,
  alt: PropTypes.string,
  section: PropTypes.array.isRequired,
};

const ServicesPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <ServicesPageTemplate
        content={post.html}
        heroimagetitle={post.frontmatter.heroimagetitle}
        heroimage={post.frontmatter.heroimage}
        heroalt={post.frontmatter.heroalt}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        alt={post.frontmatter.alt}
        section={post.frontmatter.section}
        linkretailerpage={post.frontmatter.linkretailerpage}
        linkprojectspage={post.frontmatter.linkprojectspage}
        linklandingpage={post.frontmatter.linklandingpage}
        linkproductspage={post.frontmatter.linkproductspage}
        linkbrandpage={post.frontmatter.linkbrandpage}
        linkcontactpage={post.frontmatter.linkcontactpage}
        projectlink={post.frontmatter.projectlink}
        productlink={post.frontmatter.productlink}
        linktitle={post.frontmatter.linktitle}
      />
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServicesPage;

export const servicesPageQuery = graphql`
  query ServicesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "services-page" } }) {
      id
      html
      frontmatter {
        linktitle
        linkretailerpage
        linkprojectspage
        linklandingpage
        linkproductspage
        linkbrandpage
        linkcontactpage
        projectlink
        productlink
        heroimagetitle
        heroalt
        heroimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        title
        alt
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        section {
          title
          body
          imagegallery {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
