/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import Content, { HTMLContent } from "./Content";
import StyledLink from "./StyledLink";

const Sectionblockmargin = ({ content, title, alt, image, href, linktext }) => {
  const PageContent = HTMLContent || Content;
  return (
    <section
      sx={{
        display: "flex",
        flexDirection: ["column", null, "row"],
        color: "text",
        mx: [0, 4, 5],
        mt: [0, null, 7],
      }}
    >
      {image ? (
        <div
          sx={{
            flex: 3,
            px: [4, 0],
            div: {
              height: "100%",
              minHeight: "300px",
              maxHeight: ["300px", "800px", "650px"],
            },
          }}
        >
          <PreviewCompatibleImage
            imageInfo={{
              image: image,
              alt: alt,
            }}
          />
        </div>
      ) : null}

      <article
        sx={{
          flex: 2,
          px: [4, 0, 6],
          mt: [6, null, 0],
          mb: [3, 6],
          alignSelf: "center",
          order: [-1, null, 2],
        }}
      >
        <h2 sx={{ mt: "0px", fontSize: [5, 5, 6] }}>{title}</h2>
        <div sx={{ p: { my: 3 } }}>
          <PageContent content={content} />
        </div>
        {href ? <StyledLink href={href} text={linktext} /> : null}
      </article>
    </section>
  );
};

export default Sectionblockmargin;

Sectionblockmargin.propTypes = {
  section: PropTypes.object,
};
