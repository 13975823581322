/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Fragment } from "react";

const ImageCarousel = ({ images, title }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 1,
    swipeToSlide: true,
    dots: true,
    focusOnSelect: true,
    className: "image-carousel-slick",
  };
  return (
    <Fragment>
      {images ? (
        <Slider {...settings}>
          {images.map((image, index) => {
            return (
              <div
                key={index}
                sx={{
                  height: ["100px", "200px", "300px"],
                  div: {
                    height: "100%",
                  },
                }}
              >
                <PreviewCompatibleImage
                  imageInfo={{
                    image: image.image,
                    alt: image.alt,
                  }}
                />
              </div>
            );
          })}
        </Slider>
      ) : null}
    </Fragment>
  );
};

export default ImageCarousel;

ImageCarousel.propTypes = {
  images: PropTypes.array.isRequired,
  title: PropTypes.string,
};
